import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {SectionTitle,ExploreMore,Container,SectionPage, SectionDescription} from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"


const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 0 -10px;
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
`
const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  text-align:center;
  @media (min-width: 992px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  
`
const RightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
 
`

const RTOChooseImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "rto-choose.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} alt='RTO Choose' />
}


class WhyChooseRto extends React.Component {
	constructor(props) {
		super(props)
		this.applyClick = this.applyClick.bind(this);
	}
	applyClick() {
  	const { applyCallBack } = this.props;
  	if (applyCallBack) {
      applyCallBack();
    }
  }
	render() {
		const { data } = this.props
		return(
			<SectionPage
			ept="80px"
			epb="80px"
			xpt="40px"
			xpb="40px"
			pt="30px"
			pb="30px"
			bg="#fff"
			>

				<Container>
					<SectionTitle mb="20px" maxWidth="920px">{data.title}</SectionTitle>
					<MainPanel>
						<LeftPanel>
							<SectionDescription textAlign="left" maxWidth="620px" dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
						</LeftPanel>
						<RightPanel>
							<RTOChooseImage />
						</RightPanel>						
					</MainPanel>
					<ExploreMore><button onClick={this.applyClick} className="btn-lg"><PrimaryButton text="Get Started" /></button></ExploreMore>
				</Container>
			</SectionPage>
		)
	}
}

export default WhyChooseRto