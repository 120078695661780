import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { SectionPage, BreadCrumb, SectionTitle, Container } from "../../components/Section"
import {HeroSection, HeroCover, LeftHero, RightHero, HeroPageTitle, HeroDesc, HeroAction, HeroItemAction } from "../../components/Hero"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import RightImage from "../../images/rto-process.png"
import BenefitCarousel from "../../components/BenefitCarousel"

const ImageWrap = styled.div`
	height: 400px;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, #F4FBFF 100%), 
	linear-gradient(to left, rgba(255, 255, 255, 0) 80%, #F4FBFF 100%), 
	linear-gradient(to top, rgba(255, 255, 255, 0) 80%, #F4FBFF 100%), 
	linear-gradient(to right, rgba(255, 255, 255, 0) 70%, #F4FBFF 100%), 
	url(${RightImage}) top center;
	background-size: cover;
	@media(max-width: 768px) {
		display: none;
	}
`

const ApplyButton = styled.button`
	border: 0px;
	background: rgba(255, 255, 255, 0.0001);
	color: #ED602B;
	transition: ease 0.5s;
	& svg {
		margin-left: 10px;
		transform: none !important;
		transition: ease 0.3s;
		width: 25px;
	}
	& :hover {
		cursor: pointer;
		text-decoration: none;
		& svg {
			margin-left: 20px;
		}
	}
`
class RtoHero extends React.Component {
	constructor(props) {
		super(props)
		this.applyClicked = this.applyClicked.bind(this);
	}
	applyClicked() {
		const { applyCallBack } = this.props
		applyCallBack();
	}
	render() {
		const { data, subTitle } = this.props
		return (
			<>
				<HeroSection bg="#f4fbff" bgImg={RightImage}>
					<Container>
						<BreadCrumb className="static">
							<Link to="/">Home</Link> /{" "}
							<Link to="/buying-guide">Buying Guide</Link> /{" "}
							<span>RTO Process</span>
						</BreadCrumb>
						<HeroCover>
							<LeftHero>
								<HeroPageTitle>{data.title}</HeroPageTitle>
								<HeroDesc dangerouslySetInnerHTML={{ __html: data.description.childMarkdownRemark.html }} />
								<HeroAction>
									<HeroItemAction><ApplyButton onClick={this.applyClicked}>Apply Now<ArrowIcon fill="#ED602B" /></ApplyButton></HeroItemAction>
								</HeroAction>
							</LeftHero>
							<RightHero>
								<ImageWrap />
							</RightHero>
						</HeroCover>
					</Container>
				</HeroSection>
				<SectionPage
					ept="0"
					epb="60px"
					xpt="0"
					xpb="40px"
					pt="0"
					pb="30px"
					bg="#f4fbff">
					<Container>
						<SectionTitle textAlign="center" mb="20px">{subTitle}</SectionTitle>
						<BenefitCarousel data={data.features} />
					</Container>
				</SectionPage>
			</>
		)
	}
}


export default RtoHero