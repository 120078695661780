import React from "react"
import styled from "styled-components"
import {SectionTitle,MarkdownContent,ExploreMore,Container,SectionPage} from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"

const SectionPages = styled(SectionPage)`
  position:relative;
  &:before{
    display:block;
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.02) 0.1em, transparent 0.5px);
    background-size: 3em 3em;
  }
`
const StepGrid = styled.div`
  display: grid;
  max-width: 1120px;
  width: 100%;
  margin:30px auto;
  @media (min-width: 576px) {
    margin:60px auto;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    margin:80px auto;
    grid-template-columns: repeat(4, 1fr);
  }
  .step-item{
    @media (max-width: 575.98px) {
      + .step-item{
        margin-top:30px;
      }
    }
    &:nth-child(even){
      @media (min-width: 576px) {
        margin-top:30px;
      }
      @media (min-width: 992px) {
        margin-top:40px;
      }
      @media (min-width: 1200px) {
        margin-top:60px;
      }
      @media (min-width: 1600px) {
        margin-top:80px;
      }
    }
  }
`
const StepItem = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: ${props => props.mt};
  padding: 5px 30px;
  & h3 {
    color: rgba(255, 255, 255, 0.3);
    text-align: left;
    @media (min-width: 1200px) {
      font-size: 46px;
      line-height: 56px;
    }
    @media (min-width: 1600px) {
      font-size: 56px;
      line-height: 66px;
    }
  }
  & p {
    color: #fff;
    text-align: left;
  }
`
class HowToApplyRto extends React.Component {
	constructor(props) {
		super(props)
		this.applyClick = this.applyClick.bind(this);
	}
	applyClick() {
  	const { applyCallBack } = this.props;
  	if (applyCallBack) {
      applyCallBack();
    }
  }
	render() {
		const { data } = this.props
		return(
			<SectionPages
			ept="80px"
			epb="80px"
			xpt="40px"
			xpb="40px"
			pt="30px"
			pb="30px"
			bg="#1E4156"
			>
				<Container>
					<SectionTitle color="white" textAlign="center">{data.title}</SectionTitle>
					<MarkdownContent
						textAlign="center"
						color="white"
						dangerouslySetInnerHTML={{
						__html: data.description.childMarkdownRemark.html,
						}}
					/>
					<StepGrid>
						{
							data.features.map((item, i)=>{
								if(i===0 || i===2) {
									return(
										<StepItem className="step-item">
											<h3>0{i+1}</h3>
											<p>{item.content}</p>
										</StepItem>
									)
								} else {
									return(
										<StepItem className="step-item">
											<h3>0{i+1}</h3>
											<p>{item.content}</p>
										</StepItem>
									)
								}
							})
						}
					</StepGrid>
					<ExploreMore><button onClick={this.applyClick} className="btn-lg"><PrimaryButton text="Apply Now" /></button></ExploreMore>
				</Container>
		</SectionPages>
		)
	}
}

export default HowToApplyRto