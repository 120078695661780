import React from "react"
import styled from 'styled-components'
import {
  SectionPage,
  SectionTitle,
  LeftTriangle,
  RightTriangle,
  Container,
  SectionDescription
} from "../../components/Section"
// import RtoForm from "../../components/RtoForm"
import QuickContactForm from "../../components/QuickContactForm"
import {
  HeroFormWrap,
  HeroFormBody,
} from "../../components/Hero"

const LeftTriangles = styled(LeftTriangle)`
	position:absolute;
	left:0;
	bottom:0;
	margin-top:0;
`
const RightTriangles = styled(RightTriangle)`
	position:absolute;
	right:0;
	bottom:0;
	margin-top:0;	
`


const RtoFormSection = ({ data, location, formName }) => {
  return (
    <SectionPage
      ept="80px"
      epb="80px"
      xpt="60px"
      xpb="60px"
      pt="40px"
      pb="40px"
      bg="#F4FBFF">
      <Container maxWidth="950px" zIndex="1">
        <SectionTitle textAlign="center" maxWidth="650px">{data.title}</SectionTitle>
        <SectionDescription
          dangerouslySetInnerHTML={{
            __html: data.description.childMarkdownRemark.html,
          }}
        />
        {/* <RtoForm location={location} /> */}
        <HeroFormWrap>
          <HeroFormBody>
            <QuickContactForm location={location} formName={formName ? formName : "RTO Process Form"} />
          </HeroFormBody>
        </HeroFormWrap>
      </Container>
      <LeftTriangles /><RightTriangles />
    </SectionPage>
  )
}

export default RtoFormSection
